import { Input } from 'antd'
import React, { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { IExtendedProject } from '../../app/types/models/project'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { useGetMembers } from '../../hooks/use-get-members'
import { SearchIcon } from '../../image_files/icons/SearchIcon'
import { projectsService } from '../../services/projects-service.'
import { currentProjectSelectors } from '../../store/currentProject'
import {
    setProject
} from '../../store/currentProject/actions'
import { projectsSelectors } from '../../store/projects'
import { showNotify } from '../../utils/helpers/showNotice'
import './projectList.css'

const ProjectList= () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pathname = window.location.pathname
    const location = useLocation()
    const projects = useSelector(projectsSelectors.selectProjects)
    const [currentProjectId, setCurrentProjectId] = useState(null)
    const [filteredProjects, setFilteredProjects] = useState<IExtendedProject[]>(projects || [])
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )

    const {refetch:refetchMembers} = useGetMembers()

    const searchHandler = (e) => {
        const value = e.target.value
        const searchedProjects = projects
            ? projects?.filter((f) => {
                  if (e.target.value.length > 0) {
                      return f['name']
                          .toLowerCase()
                          .includes(value.toLowerCase().trim())
                  }
              })
            : []
        setFilteredProjects(
            value.length > 0 ? searchedProjects : projects,
        )
    }

    const handleSelectProject = useCallback(
        async (id: number) => {
            try {
                const res = await projectsService.getCurrentProject(id)
                dispatch(setProject({ project: res }))

                
                // navigate(`/scheduler/${id}/tasks`)
            } catch (err) {
                showNotify(err.message,{type:'error'})
            }
        },
        [currentProject],
    )

    const changeCurrentProjectHandler = (id:number) => {
        setCurrentProjectId(id)
        refetchMembers(id)
        handleSelectProject(id)
    }

    const showValidRoute = (item: any) => {
        const arr = pathname.split('/')
        let currentPage = ''
        if (arr && arr.length > 1) {
            currentPage = arr[arr.length - 1]
        }
        return `${item.id}/${currentPage}`
    }

    return (
        <div className="scheduler__projectList-inner">
            {projects?.length > 10 && (
                <div className={'scheduler__projectList-searchWrap'}>
                    <Input
                        className={'scheduler__projectList-search'}
                        onChange={searchHandler}
                        prefix={<SearchIcon />}
                    />
                </div>
            )}
            <SimpleBar
                className="scheduler__projectList-list"
                style={{ maxHeight: 100 }}
                autoHide={false}
            >
                {filteredProjects.map((item) => {
                    return (
                        <NavLink
                            to={showValidRoute(item)}
                            className="scheduler__projectList-link"
                            key={item?.id}
                        >
                            <div
                                className="scheduler__projectList-item"
                                key={item?.id}
                                onClick={() =>
                                    changeCurrentProjectHandler(item?.id)
                                }
                                style={
                                    currentProjectId === item.id
                                        ? { color: '#8267CE' }
                                        : {}
                                }
                            >
                                {item.name}
                            </div>
                        </NavLink>
                    )
                })}
            </SimpleBar>
        </div>
    )
}

export default memo(ProjectList)
