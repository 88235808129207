import React, { FC, ReactElement, useState } from 'react'
import './index.css'
import { AccordionArrow } from '../../image_files/icons/AccordionArrow'

interface IProps {
    title: string
    children?: ReactElement
    className?: string
    activeStatus?: boolean
    handleCollapse?:(status:boolean)=>void
}

export const CustomAccordion: FC<IProps> = ({
    title,
    children,
    className,
    activeStatus,
    handleCollapse,
}) => {
    const [collapsed, setCollapsed] = useState(
        activeStatus ? activeStatus : false,
    )

    const handleCollapseStatus = ()=>{
        setCollapsed(!collapsed)

        handleCollapse && handleCollapse(!collapsed)
    }
    return (
        <div
            className={
                className
                    ? `scheduler-accordion ${className}`
                    : 'schedulerRu-accordion'
            }
        >
            <div className="scheduler-accordion__top">
                <div
                    className="scheduler-accordion__arrow"
                    onClick={handleCollapseStatus}
                >
                    <AccordionArrow
                        style={collapsed ? { transform: 'rotate(180deg)' } : {}}
                        className={'scheduler-accordion__arrowIcon'}
                    />
                </div>
                <div
                    className="scheduler-accordion__title"
                    onClick={handleCollapseStatus}
                >
                    {title ? title : 'Details'}
                </div>
            </div>
            <div
                className="scheduler-accordion__bottom"
                style={
                    !collapsed ? { maxHeight: '0px' } : { maxHeight: '1000px' }
                }
            >
                <div
                    className="scheduler-accordion__bottom-inner"
                    style={
                        !collapsed
                            ? {
                                  transform: 'translateY(-100%)',
                                  visibility: 'hidden',
                              }
                            : { transform: 'translateY(0)' }
                    }
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
