import { DatePicker } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import { DefaultOptionType } from 'antd/lib/select'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RoadMapDetalizationTypes } from '../../../../app/types/enums'
import { ITask } from '../../../../app/types/models/tasks'
import SelectInLog from '../../../../shared/select/Select'
import { selectTaskData } from '../../../../store/tasks/selectors'
import { RoadmapContext } from '../../providers'
import { getMinPeriodFromTasksList } from '../../services'
import { IDetalizationData } from '../../types'

const RoadmapDetalizationFilter = () => {
    const { t, i18n } = useTranslation()
    const { results: tasks } = useSelector(selectTaskData)
    const { detalizationData, setDetalizationData } =
        useContext(RoadmapContext)
    const [fieldsData, setFieldsData] = useState<IDetalizationData>({
        detalization: RoadMapDetalizationTypes.MONTH,
        startPeriod: new Date() as string | Date,
        endPeriod: new Date() as string | Date,
    })

    const detailsSelectOptions: DefaultOptionType[] = [
        {
            label: t('week'),
            value: RoadMapDetalizationTypes.MONTH,
            title: '',
        },
        {
            label: t('month'),
            value: RoadMapDetalizationTypes.QUARTER,
            title: '',
        },
        {
            label: t('year'),
            value: RoadMapDetalizationTypes.YEAR,
            title: '',
        },
    ]

    const handleChangePeriod = (value: any[]) => {
        if (Array.isArray(value)) {
            setFieldsData((prev) => ({
                ...prev,
                startPeriod: moment(value[0]).format('YYYY-MM-DD'),
                endPeriod: moment(value[1]).format('YYYY-MM-DD'),
            }))

            setDetalizationData((prev) => ({
                ...prev,
                startPeriod: moment(value[0]).format('YYYY-MM-DD'),
                endPeriod: moment(value[1]).format('YYYY-MM-DD'),
            }))
        }
    }

    useEffect(() => {
        if (detalizationData.detalization) {
            setFieldsData((prev) => ({
                ...prev,
                detalization: detalizationData.detalization,
            }))
        }
    }, [detalizationData])

    useEffect(() => {
        const periodData = getMinPeriodFromTasksList(tasks)
        setFieldsData((prev) => ({
            ...prev,
            startPeriod: periodData.min,
            endPeriod: periodData.max,
        }))
        setDetalizationData({
            detalization: fieldsData.detalization,
            startPeriod: periodData.min,
            endPeriod: periodData.max,
        })
    }, [tasks])

    return (
        <div className="roadMap-top-item roadMap-top-item-detalization">
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('detalization')}
                </div>
                <SelectInLog
                    options={detailsSelectOptions}
                    value={fieldsData.detalization}
                    className="roadmap-filter-field"
                    onChange={(e) => {
                        setFieldsData((prev) => ({ ...prev, detalization: e }))
                        setDetalizationData({ ...fieldsData, detalization: e })
                    }}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">{t('period')}</div>
                <DatePicker.RangePicker
                    allowClear
                    format={'YYYY-MM-DD'}
                    value={[
                        moment(fieldsData.startPeriod),
                        moment(fieldsData.endPeriod),
                    ]}
                    className="datePicker-item"
                    locale={i18n.language === 'ru' ? ru_RU : en_EN}
                    onChange={handleChangePeriod}
                />
            </div>
        </div>
    )
}

export default RoadmapDetalizationFilter
