import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IComment, ITask } from "../../../../app/types/models/tasks"
import { useAppDispatch } from "../../../../hooks/redux-hooks"
import { tasksService } from "../../../../services/tasks-services"
import TabsInlog from "../../../../shared/tabs/Tabs"
import { updateTask } from "../../../../store/tasks/actions"
import { errorsHandler } from "../../../../utils/helpers/errors/errors-hendler"
import { showNotice, showNotify } from "../../../../utils/helpers/showNotice"
import { Comment } from "../../../taskEditSpace/components/Comment/Comment"
import { UITextEditor } from "../../../taskEditSpace/components/Description/Description"
import { SubTask } from "../../../taskEditSpace/components/SubTask/SubTask"
import FileList from "../../../taskEditSpace/components/TaskFiles/TasksFiles"

const RoadMapUpdateTaskTabs = ({task,projectId}:{task:ITask,projectId:number}) =>{
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [comments, setComments] = useState<IComment[]>([])

    const updateDescription = async (description:string)=>{
        try {
            await tasksService.updateTask(
                {description},
                projectId,
                task?.slug,
            )
            dispatch(updateTask({id:task?.id, tags: task?.tags,description }))
            showNotice(t('task-updated-successfully'), t)
        } catch (error) {
            errorsHandler(error,t)
        }
    }

    const addTaskFiles = async (file: any) => {
        try {
            const fmData = new FormData()
            fmData.append('file', file)
            const res = await tasksService.addTaskFile(
                projectId,
                task?.slug,
                file,
            )
            dispatch(
                updateTask({
                    id: task?.id,
                    files: (task?.files
                        ? [...task.files, res]
                        : [file]) as any,
                }),
            )
            showNotify(t('file-added-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        }
    }
    console.log('task', task)
    const deleteTaskFile = async (id: number) => {
        try {
            await tasksService.deleteTaskFile(
                task?.project,
                task?.slug,
                id,
            )
            dispatch(
                updateTask({
                    id: task?.id,
                    files: task?.files
                        ? task.files?.filter((el) => el?.id !== id)
                        : [],
                }),
            )
            showNotify(t('file-deleted-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        }
    }
    
    const saveComment = async (value: string) => {
        try {
            const res = await tasksService.addTaskComment(
                projectId,
                task?.slug,
                { text: value },
            )
            setComments((prev) => [...prev, res])
            dispatch(
                updateTask({
                    ...task,
                    comments: [...comments, res],
                }),
            )
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const fetchComments = async () => {
        try {
            if (projectId && task?.slug) {
                const res = await tasksService.getTaskComments(
                    projectId,
                    task?.slug,
                )
                setComments(res || [])
            }
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    useEffect(() => {
        fetchComments()
    }, [task])


    const tabsItems = [
        {
            label: t('description'),
            key: '0',
            children: (
                <>
                    <UITextEditor
                        key={task?.id}
                        initialText={task?.description ?? ''}
                        handleDebounce={updateDescription}
                    />
                </>
            ),
        },
        {
            label: t('comments'),
            key: '1',
            children: <Comment onSave={saveComment} comments={comments} />,
        },
        {
            label: t('files'),
            key: '2',
            children: (
                <FileList
                    files={task?.files}
                    handleSave={addTaskFiles}
                    handleDelete={deleteTaskFile}
                />
            ),
        },
        {
            label: t('subtasks'),
            key: '3',
            children: (
                <SubTask task={task} />
            ),
        },
    ]



    return (
        <TabsInlog
        className="roadMap-add-task-tabs"
        items={task?.slug?.split('_').length < 3 ? tabsItems : tabsItems.slice(0,tabsItems.length-1)}
    />
    )
}

export default RoadMapUpdateTaskTabs;