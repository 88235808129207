import React, { useState } from 'react'
import Clipboard from 'react-clipboard.js'
import PopoverInLog from '../popoverInlog/PopoverInLog'
import { CopyDataIcon } from '../../image_files/icons/CopyDataIcon'
import TasksBoard from '../../features/taskEditSpace/components/tasksBoard/TasksBoard'
import { EditableSpan } from '../EditableSpan/EditableSpan'
import { useTranslation } from 'react-i18next'
import { showNotify } from '../../utils/helpers/showNotice'
import { ITask } from '../../app/types/models/tasks'
import { tasksService } from '../../services/tasks-services'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { updateTask } from '../../store/tasks/actions'
import './TaskNameEditBlock.css'
import { getValidText } from '../../utils/helpers/getValidText'

const TaskNameEditBlock = ({
    task,
    setLoading,
    onChange,
}: {
    task: ITask
    setLoading?: (data: boolean) => void
    onChange?: (value: string) => void
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const copyLink = window.location.href
    const [errorTitle, setErrorTitle] = useState('')
    const currentProject = useSelector(selectCurrentProject)

    const handleChangeName = async (value: string) => {
        try {
            setLoading && setLoading(true)
            await tasksService.updateTask(
                { name: value },
                currentProject?.id,
                task?.slug,
            )
            setLoading && setLoading(false)
            showNotify(t('name-changed-successfully'), { type: 'success' })
            dispatch(updateTask({ ...task, name: value }))
            onChange && onChange(value)
        } catch (error) {
            setErrorTitle('error')
            errorsHandler(error, t)
            setLoading && setLoading(false)
        }
    }

    return (
        <div className="task-name-edit-block">
            <div className="scheduler__tasksSettings-top">
                <PopoverInLog
                    content={
                        <div
                            className={
                                'font-12-normal font-white scheduler__tasksSettings-popover'
                            }
                        >
                            {t('copy-link').toString()}
                        </div>
                    }
                >
                    <Clipboard
                        data-clipboard-text={copyLink}
                        className="taskEditSpace__clipboard"
                        onSuccess={() => {
                            showNotify(t('copy-link'), { type: 'success' })
                        }}
                    >
                        <CopyDataIcon />
                    </Clipboard>
                </PopoverInLog>
                {task?.slug && (
                    <TasksBoard task={task} projectId={currentProject?.id} />
                )}
            </div>
            {task?.name && (
                <EditableSpan
                    handler={handleChangeName}
                    title={task.name}
                    error={errorTitle}
                    setError={setErrorTitle}
                    className={'scheduler__taskEditSpace-description'}
                    spanClassName={'scheduler__taskEditSpace-editSpan'}
                    inputClassName={'scheduler__taskEditSpace-editInput'}
                />
            )}
        </div>
    )
}

export default TaskNameEditBlock
