import React, { FC } from 'react'
import PopoverInLog from '../popoverInlog/PopoverInLog'
import { IUserLogoProps } from './types'
import './userLogo.css'

const setBlockStyles = (
    color: string,
    shift: string | number,
    zIndex: number,
) => {
    return {
        backgroundColor: color,
        zIndex,
        transform: String(shift),
    }
}

export const UserLogo: FC<IUserLogoProps> = (props) => {
    const { value, color, zIndex, leftShift, title, member } = props
    return (
        <>
            <PopoverInLog
                content={
                    <div
                        className={
                            'font-12-normal font-white mnemMatching-popover'
                        }
                    >
                        {title}
                    </div>
                }
            >
                {member && member?.user?.avatar ? (
                    <div
                        className="scheduler__menu-userLogo"
                        style={setBlockStyles(color, leftShift, zIndex)}
                    >
                        <img
                            className={'scheduler__menu-userImg'}
                            src={member?.user?.avatar?.small}
                            alt="user image"
                        />
                    </div>
                ) : (
                    <div
                        className="scheduler__menu-userLogo"
                        style={setBlockStyles(color, leftShift, zIndex)}
                    >
                        <span>{value}</span>
                    </div>
                )}
            </PopoverInLog>
        </>
    )
}
