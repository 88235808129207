import { DatePicker, Spin } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import 'simplebar-react/dist/simplebar.min.css'
import { PriorityTypes } from '../../app/types/enums'
import { ITasksFilterParams } from '../../app/types/models/tasks'
import TasksList from '../../entities/tasksList/TasksList'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { UpIcon } from '../../image_files/icons/UpIcon'
import { tasksService } from '../../services/tasks-services'
import { CreatePanel } from '../../shared/CreatePanel/CreatePanel'
import { Pagination } from '../../shared/Pagination/Pagination'
import InputInLog from '../../shared/input/Input'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksActions, tasksSelectors } from '../../store/tasks'
import { setTasksLoadingStatus } from '../../store/tasks/actions'
import {
    selectIsLoadingTasksStatus,
    selectTaskData,
    selectTasks,
} from '../../store/tasks/selectors'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { TaskEditSpace } from '../taskEditSpace/TaskEditSpace'
import '../../simpleBar.css'
import './tasks.css'
import { statusesServices } from '../../services/statuses-services'
import { TASKS_LIMIT } from '../../app/constants'

const Tasks = () => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const { setTasks } = tasksActions
    const isLoadingTasks = useSelector(selectIsLoadingTasksStatus)
    const [isLoadingCurrentTask, setIsLoadingCurrentTask] = useState(false)
    const {results:tasks,count} = useSelector(selectTaskData)
    const projectId = Number(location.pathname.split('/')[2])
    const { setStatuses } = tasksActions
    const [filterParams, setFilterParams] = useState<ITasksFilterParams>({
        ordering: 'created_at',
    })
    const statuses = useSelector(tasksSelectors.selectStatuses) || []

    //хэндлеры
    const handleAddTask = async (
        value: string,
        priorityStatus: PriorityTypes,
    ) => {
        try {
            const targetStatus = statuses.find(
                (s) => s?.name_en === 'No status',
            )
            if (targetStatus && targetStatus?.id !== undefined) {
                const newTask = {
                    name: value.trim(),
                    priority: priorityStatus,
                    status: targetStatus.id,
                }
                await tasksService.createTask(newTask, projectId)
                await handleChangePagination({ limit: 20, offset: 0 })
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchTasks = useCallback(async () => {
        try {
            dispatch(setTasksLoadingStatus(true))
            const pathname = location.pathname
            const pathItemsList = pathname.split('/').filter((p) => p !== '')

            if (
                pathItemsList[1] !== undefined &&
                Number(pathItemsList[1]) &&
                Object.keys(filterParams).length > 0
            ) {
                const res = await tasksService.getTasks(
                    Number(pathItemsList[1]),
                    filterParams,
                )
                dispatch(setTasks(res))
                dispatch(setTasksLoadingStatus(false))
            }
        } catch (e) {
            errorsHandler(e, t)
        } finally {
            dispatch(setTasksLoadingStatus(false))
        }
    }, [projectId, filterParams])

    const searchValueHandler = (value: string) => {
        setFilterParams({ ...filterParams, name__icontains: value })
    }

    const sortTasksByAgeHandler = () => {
        if (filterParams?.ordering === 'created_at') {
            setFilterParams({ ...filterParams, ordering: '-created_at' })
        } else {
            setFilterParams({ ...filterParams, ordering: 'created_at' })
        }
    }

    const onChangeRangeDate = (value: any[]) => {
        if (Array.isArray(value)) {
            const startValue = moment(new Date(value[0])).format('YYYY-MM-DD')
            const endValue = moment(new Date(value[1])).format('YYYY-MM-DD')
            setFilterParams({
                ...filterParams,
                created_at__range: `${startValue},${endValue}`,
            })
        } else {
            setFilterParams({ ...filterParams, created_at__range: `` })
        }
    }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    const getTasksByUrl = () => {
        const pathname = location.pathname
        const pathItemsList = pathname.split('/').filter((p) => p !== '')

        if (pathItemsList[0] === 'scheduler' && pathItemsList[2] === 'tasks') {
            fetchTasks()
        }
    }

    useEffect(() => {
        getTasksByUrl()
    }, [filterParams, projectId])

    useEffect(() => {
        if (projectId !== undefined) {
            statusesServices
                .getStatuses(projectId)
                .then((res) => {
                    dispatch(setStatuses({ statuses: res }))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [projectId])

    const showEditSpace = () => {
        if (isLoadingCurrentTask || isLoadingTasks) {
            return (
                <div className="task-space-loading">
                    <Spin size="large" />
                </div>
            )
        } else if (!tasks || tasks?.length === 0) {
            return <></>
        } else {
            return (
                <Routes>
                    <Route path={`:slug/*`} element={<TaskEditSpace />} />
                </Routes>
            )
        }
    }

    return (
        <div className="scheduler__tasks">
            <div className="scheduler__tasksList">
                <CreatePanel
                    addTask={handleAddTask}
                    prioritySelect={true}
                    title={t('tasks')}
                    placeholder={t('input-task-name')}
                    className={'scheduler__addTaskPanel'}
                    topPanelClassName={'scheduler__addTaskPanel-top'}
                />
                {tasks && tasks.length > 0 ? (
                    <>
                        <div className={'scheduler__tasks-filter'}>
                            <div className={'scheduler__tasks-filter-search'}>
                                <InputInLog
                                    placeholder={t('input-task-name')}
                                    onChange={searchValueHandler}
                                    debouncedMode={true}
                                />
                            </div>
                            <div className={'scheduler__tasks-sort-items'}>
                                <DatePicker.RangePicker
                                    allowClear
                                    format={'YYYY-MM-DD'}
                                    className="datePicker-item"
                                    locale={
                                        i18n.language === 'ru' ? ru_RU : en_EN
                                    }
                                    onChange={onChangeRangeDate}
                                />
                                <div
                                    className="font-14-normal scheduler__tasks-sort-age"
                                    onClick={sortTasksByAgeHandler}
                                >
                                    <span>
                                        {filterParams.ordering === 'created_at'
                                            ? t('new-plural').toString()
                                            : t('old-plural').toString()}
                                    </span>
                                    <UpIcon
                                        className={
                                            filterParams.ordering ===
                                            '-created_at'
                                                ? 'scheduler__tasks-sort-age--down'
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <TasksList isLoading={isLoadingTasks} />
                        <Pagination
                        count={count}
                        limit={TASKS_LIMIT}
                            onChange={handleChangePagination}
                        />
                    </>
                ) : (
                    !isLoadingTasks && (
                        <div className="font-14-normal scheduler__absent-tasks-message">
                            {t('tasks-absent-message')}
                        </div>
                    )
                )}
            </div>
            {showEditSpace()}
        </div>
    )
}

export default Tasks
