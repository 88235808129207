import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import './AddTextButton.css'
import UiButton from '../ui/button/UiButton'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

interface IProps {
    placeholder?: string
    title: string

    addText: (value: string) => void
}

const AddTextButton: FC<IProps> = ({ addText, placeholder, title }) => {
    const [inputMode, setInputMode] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const wrapperRef = useRef()
    useOnClickOutside([wrapperRef], () => {
        setInputMode(false)
    })
    const onKeyPressHandler = (e) => {
        if (e.charCode === 13 && inputValue.length > 0) {
            addText(inputValue)
            setInputValue('')
            setInputMode(false)
        }
    }

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.currentTarget.value)
    }

    return (
        <div className={'addTextButton'}>
            <UiButton
                style={
                    inputMode ? { background: 'rgba(217, 217, 217, 0.2)' } : {}
                }
                type="primary"
                onClick={() => setInputMode(!inputMode)}
            >
                + {title}
            </UiButton>
            <div
                ref={wrapperRef}
                className="addTextButton__inputForm"
                style={
                    !inputMode
                        ? { maxHeight: 0 }
                        : { maxHeight: '100px', marginTop: '12px' }
                }
            >
                <input
                    className="addTextButton__inputForm-input"
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onKeyPress={onKeyPressHandler}
                    onChange={onChangeHandler}
                />
            </div>
        </div>
    )
}

export { AddTextButton }
