import React, { SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITasksFilterParams, SortTaskTypes } from '../../../../app/types/models/tasks'
import { PlusIcon } from '../../../../image_files/icons/PlusIcon'
import { UpIcon } from '../../../../image_files/icons/UpIcon'
import { CreatePanel } from '../../../../shared/CreatePanel/CreatePanel'
import { RoadmapContext } from '../../providers'
import './RoadMapCalendarTop.css'


const RoadMapCalendarTop = ({
    setFilterParams,
}: {
    setFilterParams: (data: SetStateAction<ITasksFilterParams>) => void
}) => {
    const { t } = useTranslation()
    const { sortParams,createTask,setSortParams } = useContext(RoadmapContext)
    

    const sortByType = (type: SortTaskTypes) => {
        setFilterParams((prev) => {
            let orderingStr = prev?.ordering ?? ''
            let orderingParts = []
            if (orderingStr.length > 0) {
                orderingParts = orderingStr.split(',')
            }

            if (sortParams.includes(type)) {
                setSortParams([])
            } else {
                setSortParams([type])
            }

            if (orderingParts.length > 0 && orderingParts.includes(type)) {
                orderingParts = []
            } else {
                orderingParts = [type]
            }
            return { ...prev, ordering: orderingParts.join(',') }
        })
    }

    const getValidIcon = (type: SortTaskTypes) => {
        let className = ''
        console.log('sortParams', sortParams)
        if (sortParams.includes(type))
            className = 'scheduler__tasks-sort-age--down'

        return <UpIcon className={className} />
    }

    return (
        <div className="roadMap-calendar-top">
            <div className="roadMap-items-top">
                <div className="roadMap-items-top-columns">
                    <CreatePanel
                        placeholder={t('input-task-name')}
                        prioritySelect={true}
                        panelIcon={
                            <div className="roadMap-calendar-icon-wrap">
                                <PlusIcon circle={false} />
                            </div>
                        }
                        addTask={createTask}
                    />
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('due_date_end')}
                    >
                        <span>{t('last-deadline')}</span>
                        {getValidIcon('due_date_end')}
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('slug')}
                    >
                        <span>{t('task-code')}</span>
                        {getValidIcon('slug')}
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('name')}
                    >
                        <span>{t('task-name')}</span>
                        {getValidIcon('name')}
                    </div>
                    <div>
                        <span>{`${t('supervisor')}/ ${t('assignees')}`}</span>
                    </div>
                    <div
                        className="cursor-pointer roadMap-sort-item"
                        onClick={() => sortByType('status')}
                    >
                        <span>{t('status')}</span>
                        {getValidIcon('status')}
                    </div>
                    <div>
                        <span>{t('additional-info')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoadMapCalendarTop
