import { ITag, ITask } from "../../app/types/models/tasks"

export enum TagsTypes {
    RESENT = 'recent',
    SYSTEM = 'system',
}

export interface ITagsDropdownProps {
    projectId: number
    currentTask: ITask

    createTag?:(data:ITag)=>void
}