import { DatePicker, Modal } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { FC, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../../../app/types/common'
import { PriorityTypes } from '../../../../app/types/enums'
import { ITag, ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import useDebounce from '../../../../hooks/useDebounce'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { tasksService } from '../../../../services/tasks-services'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../../../shared/PrioritySelect/PrioritySelect'
import { TagsDropdown } from '../../../../shared/TagsDropdown/TagsDropdown'
import InputInLog from '../../../../shared/input/Input'
import SelectInLog from '../../../../shared/select/Select'
import { stopEvent } from '../../../../shared/stopEvent'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { membersSelectors } from '../../../../store/members'
import { updateTask } from '../../../../store/tasks/actions'
import {
    selectStatuses,
    selectTaskData,
} from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotice, showNotify } from '../../../../utils/helpers/showNotice'
import { RoadmapContext } from '../../providers'
import './RoadMapUpdateTaskBlock.css'
import RoadMapUpdateTaskTabs from './RoadMapUpdateTaskTabs'
import TaskNameEditBlock from '../../../../shared/taskNameEditBlock/TaskNameEditBlock'

interface IProps {
    task?: ITask
    isOpen: boolean
    className?: string

    closeModal?: () => void
}

const RoadMapUpdateTaskBlock: FC<IProps> = (props) => {
    const { task, isOpen, closeModal } = props
    const { t, i18n } = useTranslation()
    const { setTagsOptions } = useContext(RoadmapContext)
    const dispatch = useAppDispatch()
    const statuses = useSelector(selectStatuses)
    const { results: tasks } = useSelector(selectTaskData)
    const currentProject = useSelector(selectCurrentProject)
    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])
    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])
    const members = useSelector(membersSelectors.selectMembers)
    const [localTask, setLocalTask] = useState<ITask | undefined>(task)
    const [fieldsData, setFieldsData] = useState({
        name: '',
        slug: '',
        priority: 'low',
        status: undefined as any,
        due_date_start: '',
        due_date_end: '',
        tags: [] as number[],
        supervisor: 0 as string | number,
        doers: [] as number[],
    })
    const debouncedName = useDebounce(fieldsData.name, 500)

    const updateTaskFields = async (data: {
        name?: string
        tags?: number[]
        doers?: number[]
        description?: string
        files?: any
        priority?: string
        status?: number | null
        due_date_start?: string
        due_date_end?: string
    }) => {
        if (localTask && Object.keys(localTask).length > 0) {
            try {
                const res = await tasksService.updateTask(
                    data,
                    currentProject?.id,
                    localTask?.slug,
                )
                dispatch(updateTask({ ...res, tags: localTask?.tags }))
                showNotice(t('task-updated-successfully'), t)
            } catch (error) {
                errorsHandler(error, t)
            }
        }
    }

    const handleChangeRate = (value: any[]) => {
        if (Array.isArray(value)) {
            const due_date_start = moment(new Date(value[0])).format(
                'YYYY-MM-DD',
            )
            const due_date_end = moment(new Date(value[1])).format('YYYY-MM-DD')

            updateTaskFields({ due_date_start, due_date_end })
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    value: m?.user?.id,
                })),
            )
        }
    }

    const getValidTagsOptions = (tags: ITag[] | undefined): number[] => {
        if (tags && tags.length > 0) {
            return tags.map((el) => {
                if (typeof el !== 'number') {
                    return el?.id
                } else return el
            })
        } else return []
    }

    const addSupervisor = async (userId: string) => {
        const res = await tasksService.addTaskSuperVisor(
            currentProject?.id,
            localTask?.slug,
            {
                user: userId,
                project: currentProject?.id,
            },
        )

        const targetMember = members?.find((el) => el?.user?.id === res?.user)
        if (targetMember) {
            dispatch(
                updateTask({
                    ...localTask,
                    supervisor: { ...targetMember, id: res?.id },
                }),
            )
            setFieldsData((prev) => ({
                ...prev,
                supervisor: userId,
            }))
        }
    }

    const changeSupervisor = async (userId?: string) => {
        try {
            if (fieldsData.supervisor !== undefined) {
                //уже есть наблюдатель
                await tasksService.deleteTaskSuperVisor(
                    currentProject?.id,
                    localTask?.slug,
                    localTask?.supervisor?.id,
                )
            }
            if (userId !== undefined) {
                await addSupervisor(userId)
            } else {
                updateTask({
                    ...localTask,
                    supervisor: undefined,
                })
                setFieldsData((prev) => ({
                    ...prev,
                    supervisor: undefined,
                }))
                dispatch(
                    updateTask({
                        ...localTask,
                        supervisor: undefined,
                    }),
                )
            }
            showNotice(t('task-updated-successfully'), t)
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const changeDoers = async (ids?: number[]) => {
        try {
            const localIds = fieldsData.doers || []
            if (ids?.length > 0 && localIds?.length < ids?.length) {
                //добавляю участника
                const lastEl = ids[ids.length - 1]
                const res = await tasksService.addTaskDoer(
                    currentProject?.id,
                    localTask?.slug,
                    {
                        user: lastEl,
                        project: currentProject?.id,
                    },
                )

                const targetDoer = members?.find(
                    (val) => val?.user?.id === lastEl,
                )
                if (targetDoer) {
                    dispatch(
                        updateTask({
                            ...localTask,
                            doers: [
                                ...localTask?.doers,
                                { ...targetDoer, id: res?.id },
                            ],
                        }),
                    )
                    setFieldsData((prev) => {
                        const doers = prev?.doers || []
                        return {
                            ...prev,
                            doers: [...doers, targetDoer?.user?.id],
                        }
                    })
                } else {
                    console.log(
                        'member with id user id ===' + lastEl + ' didn-t find',
                    )
                }
            } else {
                //удаляю
                if (ids?.length > 0) {
                    const targetDoer = localTask?.doers?.find(
                        (el) => !ids.includes(el?.user?.id),
                    )
                    await tasksService.deleteTaskAssignee(
                        currentProject?.id,
                        localTask?.slug,
                        targetDoer?.id,
                    )

                    dispatch(
                        updateTask({
                            ...localTask,
                            doers: localTask?.doers?.filter(
                                (el) => el?.id !== targetDoer?.id,
                            ),
                        }),
                    )
                    setFieldsData((prev) => ({
                        ...prev,
                        doers: prev?.doers?.filter(
                            (val) => val !== targetDoer?.user?.id,
                        ),
                    }))
                } else {
                    const targetDoer = localTask?.doers[0]
                    if (targetDoer) {
                        await tasksService.deleteTaskAssignee(
                            currentProject?.id,
                            localTask?.slug,
                            targetDoer?.id,
                        )

                        dispatch(
                            updateTask({
                                ...localTask,
                                doers: [],
                            }),
                        )
                        setFieldsData((prev) => ({
                            ...prev,
                            doers: [],
                        }))
                    }
                }
            }
            showNotice(t('task-updated-successfully'), t)
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const handleCopyLink = () => {
        const changedPath = window.location.href.replace('roadmap', 'tasks')
        navigator.clipboard
            .writeText(`${changedPath}/${localTask?.slug}`)
            .then(() => {
                showNotify(t('link-copied'))
            })
    }

    const handleCreateTag = (res) => {
        if (res) {
            setTagsOptions((prev) => [
                ...prev,
                { label: res?.name, value: res?.id },
            ])
        }
    }

    const getValidStatusValue = (task: ITask) => {
        const validId =
            typeof task?.status !== 'number' ? task.status?.id : task.status

        if (validId !== undefined) {
            return validId
        } else {
            if (statusOptions && statusOptions.length > 0) {
                return statusOptions[0]?.value
            } else return null
        }
    }

    useEffect(() => {
        if (localTask !== undefined && isOpen) {
            setFieldsData((prev) => ({
                ...prev,
                name: getValidText(localTask?.name),
                slug: getValidText(localTask?.slug),
                priority: getValidText(localTask?.priority),
                status: getValidStatusValue(localTask),
                due_date_start: getValidText(localTask?.due_date_start),
                due_date_end: getValidText(localTask?.due_date_end),
                tags: getValidTagsOptions(localTask?.tags),
                doers:
                    localTask?.doers && localTask?.doers.length > 0
                        ? localTask?.doers.map((el) => el?.user?.id)
                        : undefined,
                supervisor: localTask.supervisor?.user?.id,
            }))
        }
    }, [localTask, statusOptions, isOpen])

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusesOptions(
                statuses?.map((el) => ({
                    label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
                    value: el?.id,
                })),
            )
        }
    }, [statuses, i18n])

    useEffect(() => {
        getMembersOptions()
    }, [members])

    useEffect(() => {
        if (debouncedName.length > 0 && debouncedName !== localTask?.name) {
            updateTaskFields({ name: debouncedName })
        }
    }, [debouncedName])

    useEffect(() => {
        if (task && tasks && tasks?.length > 0) {
            const targetTask = tasks.find((el) => el?.id === task?.id)
            setLocalTask(targetTask)
        }
    }, [tasks, task])

    return (
        <div
            className="roadmap-edit-task-block"
            onClick={(e) => {
                stopEvent(e)
            }}
        >
            {createPortal(
                <Modal
                    footer={null}
                    open={isOpen}
                    className="roadmap-add-task-modal"
                    onCancel={() => {
                        closeModal && closeModal()
                    }}
                >
                    <div className={'roadmap-add-task-modal-inner'}>
                        <div className="roadmap-add-task-modal-settings">
                            <div className="roadmap-add-task-modal-task-data">
                                <TaskNameEditBlock task={task}/>
                                <div className="roadmap-add-task-modal-items-wrapper">
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('priority')}
                                        </div>
                                        <PrioritySelect
                                            onChange={(e) =>
                                                updateTaskFields({
                                                    priority: e,
                                                })
                                            }
                                            currentPriority={
                                                localTask?.priority as PriorityTypes
                                            }
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('status')}
                                        </div>
                                        <SelectInLog
                                            allowClear={true}
                                            options={statusOptions}
                                            value={fieldsData.status}
                                            isMovedDropdownArrow={true}
                                            className="roadmap-filter-field"
                                            onChange={(e) =>
                                                updateTaskFields({ status: e })
                                            }
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('deadlines')}
                                        </div>
                                        <DatePicker.RangePicker
                                            allowClear
                                            format={'YYYY-MM-DD'}
                                            value={[
                                                moment(
                                                    localTask?.due_date_start,
                                                ),
                                                moment(localTask?.due_date_end),
                                            ]}
                                            className="datePicker-item"
                                            locale={
                                                i18n.language === 'ru'
                                                    ? ru_RU
                                                    : en_EN
                                            }
                                            onChange={handleChangeRate}
                                        />
                                    </div>
                                    <div className="roadmap-filter-field-wrap">
                                        <div className="roadmap-filter-field-label">
                                            {t('tags')}
                                        </div>
                                        <TagsDropdown
                                            projectId={currentProject?.id}
                                            currentTask={localTask}
                                            createTag={handleCreateTag}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="roadmap-add-task-modal-members">
                                <div className="roadmap-filter-field-wrap">
                                    <div className="roadmap-filter-field-label">
                                        {t('supervisor')}
                                    </div>
                                    <SelectInLog
                                        allowClear={true}
                                        options={membersOptions}
                                        value={fieldsData?.supervisor}
                                        isMovedDropdownArrow={true}
                                        className="roadmap-filter-field"
                                        onChange={changeSupervisor}
                                    />
                                </div>
                                <div className="roadmap-filter-field-wrap">
                                    <div className="roadmap-filter-field-label">
                                        {t('assignee')}
                                    </div>
                                    <SelectInLog
                                        options={membersOptions}
                                        isMovedDropdownArrow={true}
                                        mode="multiple"
                                        value={fieldsData?.doers}
                                        className="roadmap-filter-field"
                                        onChange={changeDoers}
                                    />
                                </div>
                            </div>
                        </div>
                        <RoadMapUpdateTaskTabs
                            task={localTask}
                            projectId={currentProject.id}
                        />
                    </div>
                </Modal>,
                document.body,
            )}
        </div>
    )
}

export default RoadMapUpdateTaskBlock
