import React from 'react'
import { FC } from 'react'
import { IIconProps } from '../../app/types/i-props'

interface IProps {
    className?: string
    onClick?: () => void
}

export const DeleteIcon: FC<IIconProps> = ({
    width,
    height,
    viewBox,
    className,
    onClick,
}) => {
    return (
        <svg
            className={className}
            width={width ? width : '24'}
            height={height ? height : '24'}
            viewBox={viewBox ? viewBox : '0 0 24 24'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M8 6L8.16858 4.73567C8.30106 3.74206 9.14863 3 10.151 3H11.849C12.8514 3 13.6989 3.74206 13.8314 4.73567L14 6"
                stroke="#2B506B"
                strokeLinejoin="round"
            />
            <path d="M4 6L18 6" stroke="#2B506B" stroke-linecap="round" />
            <path
                d="M18 8L16.2609 19.3041C16.1108 20.2798 15.2713 21 14.2842 21H7.84975C6.87134 21 6.03657 20.2922 5.87665 19.3269L4 8"
                stroke="#2B506B"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.5 18L8.5 9" stroke="#2B506B" stroke-linecap="round" />
            <path d="M12.5 18L13.5 9" stroke="#2B506B" stroke-linecap="round" />
        </svg>
    )
}
