import {
    ICalendarGroup,
    ICalendarItem,
} from '../../../../../app/types/i-roadmap-calendar'
import { getStartEndDate } from './get-start-end-date'


const getNowDate = (data: ICalendarItem,index:number) => {
    const nowYear = new Date().getFullYear()
    const nowMonth = new Date().getMonth()
    const nowDay = new Date().getDate()
    
    const month = data?.value?.month?.index || 0
    const year = data?.value?.year
    let days = data?.value?.days || []

    if(index===0){
        days = days.map((d) => (d >= 25 ? 0 : d))
    }

    if (year === nowYear && month === nowMonth) {
        const targetPosition = days.indexOf(nowDay) + 1
        if(days.includes(nowDay)){
            return (targetPosition / days.length) * 100
        }else return null
    } else return null
}

const getWeek = (week: ICalendarItem, interval: [number, number],weekI:number) => {
    return {
        ...week,
        value: {
            ...week?.value,
            interval,
            nowDate:getNowDate(week,weekI)
        },
    }
}

const getWeekList = (list: ICalendarItem[],targetDay:number,type:'start' | 'end') => {
    let status = false

    return list.map((week, weekI) => {
        let days = week?.value?.days || []

        if (weekI === 0) {
            days = days.map((d) => (d >= 25 ? 0 : d))
        }

        const targetPosition = days.indexOf(targetDay) + 1
        const targetPercent = (targetPosition / days.length) * 100

        if (days.includes(targetDay)) {
            status = true
            if(type==='start'){
                return getWeek(week, [targetPercent, 100],weekI)
            }else{
                return getWeek(week, [0, targetPercent],weekI)
            }
        } else {
            if(type==='start'){
                return getWeek(week, [0, status ? 100 : 0],weekI)
            }else{
                return getWeek(week, [0, status ? 0 : 100],weekI)
            }
            
        }
    })
}

export const getGroupsListByMonthDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        startDate: string | Date
        endDate: string | Date
    },
) => {
    const {startDay,startMonth,startYear,endDay,endMonth,endYear} = getStartEndDate(data)

    let targetStartMonth = startMonth
    let targetEndMonth = endMonth
    const targetStartGroup = calendarGroups?.find(
        (el) => el?.index === targetStartMonth,
    )
    const targetEndGroup = calendarGroups?.find(
        (el) => el?.index === targetEndMonth,
    )
    const targetStartDay = targetStartGroup?.list
        ? targetStartGroup?.list.find((week, weekI) => {
              if (weekI === 0) {
                  let list = week?.value?.days || []
                  if (startDay >= 25) {
                      return true
                  } else {
                      return list.includes(startDay)
                  }
              } else return week?.value?.days.includes(startDay)
          })
        : undefined

    if (!targetStartDay) {
        targetStartMonth = targetStartMonth + 1
    }

    const targetEndDay = targetEndGroup?.list?.find((week, weekI) => {
        if (weekI === 0) {
            let list = week?.value?.days || []
            list = list.map((d) => (d >= 25 ? 0 : d))
            return list.includes(endDay)
        } else return week?.value?.days.includes(endDay)
    })

    if (!targetEndDay) {
        targetEndMonth = targetEndMonth + 1
    }

    return calendarGroups.map((group) => {
        const getGroup = (interval: [number, number]) => {
            return {
                ...group,
                list: group.list.map((week,weekI) => {
                    return {
                        ...week,
                        value: {
                            ...week?.value,
                            interval,
                            nowDate:getNowDate(week,weekI)
                        },
                    }
                }),
            }
        }

        if (group.year === startYear && group.year === endYear) {
            if (
                group?.index === targetStartMonth &&
                group?.index === targetEndMonth
            ) {
                return {
                    ...group,
                    list: group.list.map((week, weekI) => {
                        let days = week?.value?.days || []
                        const startPosition = days.indexOf(startDay) + 1
                        const endPosition = days.indexOf(endDay) + 1
                        const firstPercent = (startPosition / days.length) * 100
                        const endPercent = (endPosition / days.length) * 100

                        if (weekI === 0) {
                            days = days.map((d) => (d >= 25 ? 0 : d))
                        }
                        if (days.includes(startDay) && !days.includes(endDay)) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [firstPercent, 100],
                                    nowDate:getNowDate(week,weekI)
                                },
                            }
                        } else if (
                            days.includes(startDay) &&
                            days.includes(endDay) &&
                            days.indexOf(startDay) < days.indexOf(endDay)
                        ) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [firstPercent, endPercent],
                                    nowDate:getNowDate(week,weekI)
                                },
                            }
                        } else if (
                            !days.includes(startDay) &&
                            days.includes(endDay)
                        ) {
                            return {
                                ...week,
                                value: {
                                    ...week?.value,
                                    interval: [0, endPercent],
                                    nowDate:getNowDate(week,weekI)
                                },
                            }
                        } else return week
                    }),
                }
            } else if (
                group?.index === targetStartMonth &&
                group?.index !== targetEndMonth
            ) {
                return {
                    ...group,
                    list: getWeekList(group.list,startDay,'start'),
                }
            } else if (
                group?.index !== targetStartMonth &&
                group?.index === targetEndMonth
            ) {
                return {
                    ...group,
                    list: getWeekList(group.list,endDay,'end'),
                }
            } else if (
                group?.index !== targetStartMonth &&
                group?.index !== targetEndMonth
            ) {
                if (group?.index < targetStartMonth) {
                    return group
                } else if (
                    group?.index > targetStartMonth &&
                    group?.index < targetEndMonth
                ) {
                    return getGroup([0, 100])
                } else {
                    return group
                }
            }
            return group
        } else if (group.year > startYear && group.year < endYear) {
            return getGroup([0, 100])
        } else if (group.year === startYear && group.year !== endYear) {
            if (group.index === targetStartMonth) {
                return {
                    ...group,
                    list: getWeekList(group.list,startDay,'start'),
                }
            } else if (group.index < startMonth) {
                return getGroup([0, 0])
            } else {
                return getGroup([0, 100])
            }
        } else if (group.year === endYear && group.year !== startYear) {
            if (group.index === targetEndMonth) {
                return {
                    ...group,
                    list: getWeekList(group.list,endDay,'end'),
                }
            } else {
                return getGroup([0, group.index < targetEndMonth ? 100 : 0])
            }
        } else if (group.year < startYear || group.year > endYear) {
            return getGroup([0, 0])
        }
        return group
    }) as ICalendarGroup[]
}
