export const getStartEndDate = (data: {
    startDate: string | Date
    endDate: string | Date
}) => {
    let { startDate, endDate } = data
    startDate = new Date(startDate)
    endDate = new Date(endDate)

    const startYear = startDate.getFullYear()
    const startMonth = startDate.getMonth()
    const startDay = startDate.getDate()

    const endYear = endDate.getFullYear()
    const endMonth = endDate.getMonth()
    const endDay = endDate.getDate()

    return {
        startYear,
        startMonth,
        startDay,
        endYear,
        endMonth,
        endDay,
    }
}
