import React from "react"
import { IIconProps } from "../../app/types/i-props"

export const BoldIcon = ({fill="#364F6B"}:IIconProps) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7084 9.83329C13.1164 9.58483 13.4608 9.24447 13.714 8.83937C13.9672 8.43426 14.1222 7.9756 14.1667 7.49996C14.1745 7.06986 14.0974 6.64246 13.9399 6.24215C13.7824 5.84185 13.5476 5.47649 13.2489 5.16695C12.9502 4.85741 12.5935 4.60974 12.199 4.43811C11.8046 4.26647 11.3802 4.17422 10.9501 4.16663H5.54175V15.8333H11.3751C11.7844 15.8289 12.1888 15.744 12.5653 15.5833C12.9417 15.4227 13.2829 15.1894 13.5692 14.8969C13.8555 14.6044 14.0814 14.2584 14.234 13.8786C14.3866 13.4987 14.4628 13.0926 14.4584 12.6833V12.5833C14.4587 12.0059 14.2942 11.4404 13.9842 10.9533C13.6742 10.4661 13.2316 10.0776 12.7084 9.83329ZM7.20841 5.83329H10.7084C11.0636 5.8223 11.4136 5.92019 11.7115 6.11384C12.0094 6.30748 12.2409 6.5876 12.3751 6.91663C12.5108 7.35645 12.4669 7.83212 12.253 8.23967C12.0391 8.64722 11.6725 8.95352 11.2334 9.09163C11.0629 9.1416 10.8861 9.16685 10.7084 9.16663H7.20841V5.83329ZM11.0417 14.1666H7.20841V10.8333H11.0417C11.3969 10.8223 11.7469 10.9202 12.0448 11.1138C12.3427 11.3075 12.5743 11.5876 12.7084 11.9166C12.8441 12.3564 12.8002 12.8321 12.5863 13.2397C12.3724 13.6472 12.0058 13.9535 11.5667 14.0916C11.3962 14.1416 11.2194 14.1669 11.0417 14.1666Z"
                fill={fill}
            />
        </svg>
    )
}
