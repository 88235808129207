import { ICalendarGroup, ICalendarItem } from '../../../../../app/types/i-roadmap-calendar'
import { getStartEndDate } from './get-start-end-date'

const getNowDate = (data: ICalendarItem,year:number) => {
    const nowYear = new Date().getFullYear()
    const nowMonth = new Date().getMonth()
    const nowDay = new Date().getDate()
    
    const month = data?.index || 0
    let days = data?.days || []

    if (year === nowYear && month === nowMonth) {
        const targetPosition = days.indexOf(nowDay)
        if(days.includes(nowDay)){
            return (targetPosition / days.length) * 100
        }else return null
    } else return null
}

export const getGroupsByQuarterDetalization = (
    calendarGroups: ICalendarGroup[],
    data: {
        startDate: string | Date
        endDate: string | Date
    },
) => {
    const {startDay,startMonth,startYear,endDay,endMonth,endYear} = getStartEndDate(data)

    return calendarGroups.map((quarter) => {
        const quarterYear = quarter.year

        const getQuarter = (data:{
            startMonth:number,
            startDay:number,
            endMonth:number,
            endDay:number,
        }) => {
            const {startMonth,startDay,endMonth,endDay} = data
           return {...quarter,
            list: quarter.list.map((month) => {
                const monthIndex = month?.index
                const days = month?.days || []
                const daysLength = days.length
                const firstPercent = (startDay / daysLength) * 100
                const endPercent = (endDay / daysLength) * 100

                const getMonth = (interval:[number,number]) =>{
                    return {
                        ...month, value: {
                            ...month?.value,
                            interval,
                            nowDate:getNowDate(month,quarter.year)
                        },
                    }
                }

                if (monthIndex === startMonth && monthIndex === endMonth) {
                    return getMonth([firstPercent, endPercent])
                } else if (
                    monthIndex === startMonth &&
                    monthIndex !== endMonth
                ) {
                    return getMonth([firstPercent, 100])
                } else if (
                    monthIndex !== startMonth &&
                    monthIndex === endMonth
                ) {
                    return getMonth([0, endPercent])
                } else if (
                    monthIndex !== startMonth &&
                    monthIndex !== endMonth
                ) {
                    if(monthIndex>startMonth && monthIndex<endMonth){
                        return getMonth([0, 100])
                    }else if(monthIndex<startMonth || monthIndex>endMonth){
                        return getMonth([0, 0])
                    }else return month
                } else return month
            })}
        }

        if(startYear===quarterYear && endYear===quarterYear ){
            return getQuarter({startMonth,endMonth,startDay,endDay})
        }else if(startYear===quarterYear && endYear!==quarterYear ){
            return getQuarter({startMonth,endMonth:11,startDay,endDay:31})
        }else if(startYear!==quarterYear && endYear===quarterYear){
            return getQuarter({startMonth:0,endMonth,startDay:0,endDay})
        } else if(quarterYear>startYear && quarterYear<endYear){
            return {...quarter,list:quarter.list.map((month)=>{
                return {
                    ...month, value: {
                        ...month?.value,
                        interval:[0,100],
                        nowDate:getNowDate(month,quarter.year)
                    },
                }
            })}
        }else {
            return {...quarter,list:quarter.list.map((month)=>{
                return {
                    ...month, value: {
                        ...month?.value,
                        interval:[0,0],
                        nowDate:getNowDate(month,quarter.year)
                    },
                }
            })}
        }
    }) as ICalendarGroup[]
}
