import {
    ContentState,
    convertFromHTML,
    convertToRaw,
    EditorState,
    Modifier,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../../../hooks/useDebounce'
import { BoldIcon } from '../../../../image_files/icons/BoldIcon'
import { ItalicIcon } from '../../../../image_files/icons/ItalicIcon'
import { UnderlineIcon } from '../../../../image_files/icons/UnderlineIcon'
import { getValidText } from '../../../../utils/helpers/getValidText'
import './Description.css'
import { getInlineTools, textEditorColorPickerIcon, textEditorEmojiIcon, textEditorHistoryIconCodes, textEditorInlineIconCodes, textEditorListIconCodes, textEditorTextAlignIconCodes } from './icon-codes'
import './index.css'
const INITIAL_LIB_TEXT = '<p></p>'
export const UITextEditor = ({
    initialText,
    handleBlur,
    handleDebounce,
}: {
    initialText: string
    handleBlur?: (value: string) => void
    handleDebounce?: (value: string) => void
}) => {
    const [isFirstVisit, setIsFirstVisit] = useState(false)
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(
                    initialText === '<p>null</p>' || initialText === null
                        ? INITIAL_LIB_TEXT
                        : `${getValidText(initialText)}`,
                ),
            ),
        ),
    )
    const debouncedText = useDebounce(editorState, 500)
    const { t, i18n } = useTranslation()

    const customToolBar = () => {
        return {
            options: [
                'fontFamily',
                'inline',
                'fontSize',
                'list',
                'textAlign',
                'colorPicker',
                'emoji',
                'remove',
                'history',
            ],
            inline: {
                options: ['bold', 'italic', 'underline'],
                bold: {
                    icon: <BoldIcon />,
                    className: 'custom-toolbar-icon',
                    path: '../../../../image_files/icons/BoldIcon',
                }, // Замените иконки
                italic: {
                    icon: <ItalicIcon />,
                    className: 'custom-toolbar-icon',
                    path: '../../../../image_files/icons/ItalicIcon',
                },
                underline: {
                    icon: <UnderlineIcon />,
                    className: 'custom-toolbar-icon',
                    path: '../../../../image_files/icons/UnderlineIcon',
                },
            },
        }
    }

    const onEditorStateChange = (newEditorState) => {
        //   // Получаем текст без форматирования
        const contentState = newEditorState.getCurrentContent()
        const plainText = contentState.getPlainText()

        // Удаляем лишние пробелы (например, двойные пробелы)
        const trimmedText = plainText.replace(/\s+/g, ' ')

        // Если текст изменился, создаём новое состояние контента
        if (plainText !== trimmedText) {
            const selectionState = newEditorState.getSelection()
            const contentWithTrimmedSpaces = Modifier.replaceText(
                contentState,
                selectionState,
                trimmedText,
            )
            const newState = EditorState.push(
                newEditorState,
                contentWithTrimmedSpaces,
                'change-inline-style',
            )
            setEditorState(newState)
        } else {
            setEditorState(newEditorState)
        }
    }

    // Функция для удаления лишних пробелов
    const trimEditorState = (editorState) => {
        const contentState = editorState.getCurrentContent()
        const plainText = contentState.getPlainText()

        // Удаляем лишние пробелы
        const trimmedText = plainText.replace(/\s+/g, ' ')

        // Если текст изменился, создаем новое состояние контента
        if (plainText !== trimmedText) {
            const selectionState = editorState.getSelection()
            const contentWithTrimmedSpaces = Modifier.replaceText(
                contentState,
                selectionState,
                trimmedText,
            )
            return EditorState.push(
                editorState,
                contentWithTrimmedSpaces,
                'change-inline-style',
            )
        }
        return editorState
    }

    const prepareData = (st?: any) => {
        const currentState = st ? st : editorState
        const text = draftToHtml(
            convertToRaw(currentState.getCurrentContent()),
        ).toString()

        return {
            text,
            draft: currentState.getCurrentContent(),
        }
    }

    const onBlurHandler = () => {
        const trimmedState = trimEditorState(editorState)
        setEditorState(trimmedState)
        handleBlur && handleBlur(prepareData(trimmedState).text)
    }

    useEffect(() => {
        if (handleDebounce && isFirstVisit) {
            const trimmedState = trimEditorState(debouncedText)
            handleDebounce(prepareData(trimmedState).text.trim())
        } else {
            setIsFirstVisit(true)
        }
    }, [debouncedText])
    return (
        <div className="scheduleDescription">
            <div className="scheduleDescription__inner">
                <Editor
                    editorState={editorState}
                    toolbarClassName="home-toolbar"
                    wrapperClassName="home-wrapper"
                    editorClassName="home-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder={t('add-description')}
                    onBlur={onBlurHandler}
                    // spellCheck

                    localization={{
                        locale: i18n.language,
                    }}
                    toolbar={{
                        options: [
                            'fontFamily',
                            'fontSize',
                            'inline',

                            'list',
                            'textAlign',
                            'colorPicker',
                            'emoji',
                            // 'remove',
                            'history',
                        ],
                        inline: getInlineTools(textEditorInlineIconCodes),
                        list:getInlineTools(textEditorListIconCodes),
                        textAlign:getInlineTools(textEditorTextAlignIconCodes),
                        colorPicker:{
                            icon:textEditorColorPickerIcon,
                            colors:["#364f6b","#97ACF8","#DC5882","#FF4471","#3FC1C9","#34CD84","rgba(63, 193, 201, 0.40)","#FF9F2D","#FFEC43","#ffddb5","#828282","#000000"]
                        },
                        emoji:{
                            icon:textEditorEmojiIcon
                        },
                        history:getInlineTools(textEditorHistoryIconCodes),
                        fontSize: {
                            options: [
                                8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48,
                                60, 72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        fontFamily: {
                            options: [
                                'Arial',
                                'Georgia',
                                'Impact',
                                'Tahoma',
                                'Times New Roman',
                                'Verdana',
                                'Gowun Dodum',
                                'Montserrat',
                            ],
                            className: 'home-fontFamily',
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                    }}
                />
            </div>
        </div>
    )
}
