import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { IComment, ITask } from '../../app/types/models/tasks'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { getTaskSlugFromUrl } from '../../services/get-task-slug-from-url'
import { tasksService } from '../../services/tasks-services'
import { CustomAccordion } from '../../shared/customAccordion/CustomAccordion'
import SpinInLog from '../../shared/spin/spin'
import TaskNameEditBlock from '../../shared/taskNameEditBlock/TaskNameEditBlock'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksSelectors } from '../../store/tasks'
import { setTasksLoadingStatus, updateTask } from '../../store/tasks/actions'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../utils/helpers/showNotice'
import { Comment } from './components/Comment/Comment'
import { UITextEditor } from './components/Description/Description'
import { Details } from './components/Details/Details'
import { SubTask } from './components/SubTask/SubTask'
import FileList from './components/TaskFiles/TasksFiles'
import './taskEditSpace.css'

export const TaskEditSpace = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const taskSlug = getTaskSlugFromUrl(location.pathname)
    const tasksList = useSelector(tasksSelectors.selectTasks)
    const [comments, setComments] = useState<IComment[]>([])
    const currentProject = useSelector(selectCurrentProject)
    const [currentTask, setCurrentTask] = useState<ITask>({} as ITask)

    const getTask = useCallback(
        async (projectId: number, taskSlug: string) => {
            try {
                setLoading(true)
                const res = await tasksService.getCurrentTask(
                    projectId,
                    decodeURIComponent(taskSlug),
                )
                setCurrentTask(res)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                errorsHandler(error, t)
            }
        },
        [currentProject?.id, location.pathname, location.pathname],
    )

    const fetchComments = async () => {
        try {
            if (currentProject?.id && currentTask?.slug) {
                setLoading(true)
                const res = await tasksService.getTaskComments(
                    currentProject?.id,
                    currentTask?.slug,
                )
                setComments(res || [])
                setLoading(false)
            }
        } catch (error) {
            errorsHandler(error, t)
            setLoading(false)
        }
    }

    const saveComment = async (value: string) => {
        try {
            setLoading(true)
            const res = await tasksService.addTaskComment(
                currentProject?.id,
                currentTask?.slug,
                { text: value },
            )
            setComments((prev) => [...prev, res])
            setLoading(false)
        } catch (error) {
            setLoading(false)
            errorsHandler(error, t)
        }
    }

    const addTaskFiles = async (file: any) => {
        try {
            const fmData = new FormData()
            fmData.append('file', file)
            const res = await tasksService.addTaskFile(
                currentProject?.id,
                currentTask?.slug,
                file,
            )
            const files = currentTask?.files
                ? [...currentTask.files, res]
                : [file]
            dispatch(
                updateTask({
                    id: currentTask?.id,
                    files,
                }),
            )
            setCurrentTask((prev) => ({ ...prev, files }))
            showNotify(t('file-added-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const deleteTaskFile = async (id: number) => {
        try {
            await tasksService.deleteTaskFile(
                currentTask?.project,
                currentTask?.slug,
                id,
            )
            const filteredFiles = currentTask?.files
                ? currentTask.files?.filter((el) => el?.id !== id)
                : []
            dispatch(
                updateTask({
                    id: currentTask?.id,
                    files: filteredFiles,
                }),
            )
            setCurrentTask((prev) => ({ ...prev, files: filteredFiles }))
            showNotify(t('file-deleted-successfully'))
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const updateDescription = async (description: string) => {
        try {
            if (description !== currentTask?.description) {
                dispatch(setTasksLoadingStatus(true))
                tasksService.updateTask(
                    { description },
                    currentProject?.id,
                    taskSlug,
                )
                dispatch(updateTask({ ...currentTask, description }))
                dispatch(setTasksLoadingStatus(false))
                showNotify(t('description-added-successfully'))
            }
        } catch (error) {
            errorsHandler(error, t)
        } finally {
            dispatch(setTasksLoadingStatus(false))
        }
    }

    useEffect(() => {
        if (currentProject?.id) {
            getTask(currentProject?.id, getTaskSlugFromUrl(location.pathname))
        }
    }, [currentProject?.id, location.pathname])

    useEffect(() => {
        fetchComments()
    }, [currentProject?.id, currentTask])

    if (tasksList.length === 0) {
        return <div className="scheduler__clearSpace"></div>
    }

    return (
        <div className={'scheduler__tasksSettings'}>
            {createPortal(<SpinInLog isLoading={loading} />, document.body)}
            <div className="scheduler__tasksSettings-inner">
                <TaskNameEditBlock
                    task={currentTask}
                    setLoading={setLoading}
                    onChange={(val) =>
                        setCurrentTask((prev) => ({ ...prev, name: val }))
                    }
                />
                <div className="scheduler__taskEditSpace-scrollbarInner">
                    <CustomAccordion
                        title={t('details')}
                        activeStatus
                        className="scheduler__tasksSettings-description"
                    >
                        <Details
                            task={currentTask}
                            setTask={setCurrentTask}
                            projectId={currentProject?.id}
                        />
                    </CustomAccordion>
                    <CustomAccordion title={t('description')} activeStatus>
                        <>
                            <UITextEditor
                                key={currentTask?.id}
                                initialText={currentTask?.description}
                                handleBlur={updateDescription}
                            />
                            <div className="mt-20">
                                <SubTask task={currentTask} />
                            </div>
                        </>
                    </CustomAccordion>
                    <CustomAccordion title={t('pin-files')} activeStatus>
                        <FileList
                            files={currentTask?.files}
                            handleSave={addTaskFiles}
                            handleDelete={deleteTaskFile}
                        />
                    </CustomAccordion>

                    <CustomAccordion
                        activeStatus={true}
                        title={t('comments')}
                        className="scheduler__tasksSettings-comment"
                    >
                        <Comment onSave={saveComment} comments={comments} />
                    </CustomAccordion>
                </div>
            </div>
        </div>
    )
}
