import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ISelectOption } from '../../../../app/types/common'
import { ITasksFilterParams } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { useGetMembers } from '../../../../hooks/use-get-members'
import useDebounce from '../../../../hooks/useDebounce'
import { SearchIcon } from '../../../../image_files/icons/SearchIcon'
import { statusesServices } from '../../../../services/statuses-services'
import InputInLog from '../../../../shared/input/Input'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../../../shared/PrioritySelect/PrioritySelect'
import SelectInLog from '../../../../shared/select/Select'
import { selectMembers } from '../../../../store/members/selectors'
import { setStatuses } from '../../../../store/tasks/actions'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { RoadmapContext } from '../../providers'
import './RoadMapFilter.css'

const RoadMapFilter = ({ isDisabled }: { isDisabled?: boolean }) => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const members = useSelector(selectMembers)
    const { filterParams, setFilterParams, tagsOptions } =
        useContext(RoadmapContext)
    const { refetch: refetchMembers } = useGetMembers()
    const projectId = Number(location.pathname.split('/')[2])
    const [name, setName] = useState<string | undefined>(undefined)
    const [slug, setSlug] = useState<string | undefined>(undefined)
    const debouncedName = useDebounce(name, 500)
    const debouncedSlug = useDebounce(slug, 500)

    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])
    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])

    const fetchStatuses = async () => {
        try {
            const statuses = await statusesServices.getStatuses(projectId)
            if (statuses && statuses?.length > 0) {
                setStatusesOptions(
                    statuses?.map((el) => ({
                        label:
                            i18n.language === 'en' ? el?.name_en : el?.name_ru,
                        value: el?.id,
                        title: '',
                    })),
                )
            }
            dispatch(setStatuses({ statuses }))
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m, i) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    title: '',
                    value: m?.user?.id,
                })),
            )
        }
    }

    const handleChangeField = (
        value: string,
        field: keyof ITasksFilterParams,
    ) => {
        setFilterParams((prev) => ({ ...prev, [field]: value }))
    }

    useEffect(() => {
         getMembersOptions()
    }, [members])

    useEffect(() => {
        if (!isNaN(projectId)) {
            fetchStatuses()
            refetchMembers(projectId)
        }
    }, [projectId, i18n])

    useEffect(() => {
        if (debouncedName !== undefined) {
            handleChangeField(debouncedName, 'name__icontains')
        }
    }, [debouncedName])

    useEffect(() => {
        if (debouncedSlug !== undefined) {
            handleChangeField(debouncedSlug, 'slug__icontains')
        }
    }, [debouncedSlug])

    useEffect(() => {
        setName(filterParams?.name__icontains)
        setSlug(filterParams?.slug__icontains)
    }, [filterParams])

    return (
        <div className="roadmap-filter">
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('task-name')}
                </div>
                <InputInLog
                    prefix={<SearchIcon />}
                    disabled={isDisabled}
                    className="roadmap-filter-field"
                    value={name}
                    onChange={setName}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('task-subtask-code')}
                </div>
                <InputInLog
                    prefix={<SearchIcon />}
                    disabled={isDisabled}
                    value={slug}
                    className="roadmap-filter-field"
                    onChange={setSlug}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('priority')}
                </div>
                <PrioritySelect
                    allowClear={true}
                    disabled={isDisabled}
                    currentPriority={filterParams.priority}
                    onChange={(e) => handleChangeField(e, 'priority')}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">{t('status')}</div>
                <SelectInLog
                    allowClear={true}
                    disabled={isDisabled}
                    options={statusOptions}
                    isMovedDropdownArrow={true}
                    className="roadmap-filter-field"
                    value={filterParams?.status}
                    onChange={(e) => handleChangeField(e, 'status')}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('supervisor')}
                </div>
                <SelectInLog
                    allowClear={true}
                    disabled={isDisabled}
                    options={membersOptions}
                    isMovedDropdownArrow={true}
                    className="roadmap-filter-field"
                    value={filterParams?.supervisor__user}
                    onChange={(e) => handleChangeField(e, 'supervisor__user')}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">
                    {t('assignee')}
                </div>
                <SelectInLog
                    allowClear={true}
                    disabled={isDisabled}
                    options={membersOptions}
                    mode="multiple"
                    isMovedDropdownArrow={true}
                    className="roadmap-filter-field"
                    value={filterParams?.doers__user}
                    onChange={(e) => handleChangeField(e, 'doers__user')}
                />
            </div>
            <div className="roadmap-filter-field-wrap">
                <div className="roadmap-filter-field-label">{t('tags')}</div>
                <SelectInLog
                    allowClear={true}
                    disabled={isDisabled}
                    options={tagsOptions}
                    mode="multiple"
                    isMovedDropdownArrow={true}
                    className="roadmap-filter-field"
                    value={filterParams?.tags}
                    onChange={(e) => handleChangeField(e, 'tags')}
                />
            </div>
        </div>
    )
}

export default RoadMapFilter
